import React, {useEffect} from "react";
import {FaDiscord, FaTwitterSquare} from "react-icons/fa";
import {MdArrowUpward, MdMenu, MdClose} from "react-icons/md";
import HeroBanner from "./assets/hero-banner.jpeg";
import Logo from "./assets/logo.png";
import TokenImg1 from "./assets/icons/2.png";
import TokenImg2 from "./assets/icons/6.png";
import TokenImg3 from "./assets/icons/lock.png";
import TokenImg4 from "./assets/icons/metaverse.png";
import UCimg1 from "./assets/icons/case.png";
import UCimg2 from "./assets/icons/revrese.png";
import UCimg3 from "./assets/icons/vr.png";
import UCimg4 from "./assets/icons/gaming.png";
import T1 from "./assets/team/1.jpeg";
import T2 from "./assets/team/2.jpeg";
import T3 from "./assets/team/3.jpeg";
import T4 from "./assets/team/4.jpeg";
import chart from "./assets/chart.png"
import {Power4, Linear} from "gsap/dist/gsap";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import $ from "jquery";
import {PieChart, Pie, Sector, Cell, ResponsiveContainer} from "recharts";
import {Dropdown} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "./custom.css"
import {
    AiFillMediumSquare,
    AiFillTwitterCircle,
    AiFillYoutube,
    AiOutlineMedium,
    BsFacebook,
    BsTelegram, IoLogoLinkedin
} from "react-icons/all";

const data = [
    {name: "Group A", value: 7},
    {name: "Group B", value: 3},
    {name: "Group C", value: 20},
    {name: "Group D", value: 5},
    {name: "Group D", value: 30},
    {name: "Group D", value: 35},
];

const COLORS = [
    "#d80081",
    "#8395a7",
    "#d80081",
    "#8395a7",
    "#d80081",
    "#8395a7",
];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
                                   cx,
                                   cy,
                                   midAngle,
                                   innerRadius,
                                   outerRadius,
                                   percent,
                                   index,
                               }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            className="graph-text"
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

function App() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
        /* Scroll-to-Top button visibility control */
        gsap.fromTo(
            ".moveToTop",
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: "#section1",
                    toggleActions: "play pause resume none",
                    start: "bottom 0%",
                    scrub: true,
                },
            }
        );
    }, []);
    /* Desktop Anchors */
    const moveToHome = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#sectionHome",
                offsetY: 65,
            },
            ease: Power4.easeInOut,
        });
    };
    const moveTo1 = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section1",
                offsetY: 35,
            },
            ease: Power4.easeInOut,
        });
    };
    const moveTo2 = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section2",
                offsetY: 35,
            },
            ease: Power4.easeInOut,
        });
    };
    const moveTo3 = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section3",
                offsetY: 35,
            },
            ease: Power4.easeInOut,
        });
    };
    const moveTo4 = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section4",
                offsetY: 35,
            },
            ease: Power4.easeInOut,
        });
    };
    const moveTo5 = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section5",
                offsetY: 35,
            },
            ease: Power4.easeInOut,
        });
    };
    /* Mobile Anchors */
    const moveTo1Mob = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section1",
                offsetY: 65,
            },
            ease: Power4.easeInOut,
        });
        $(".header-mob").slideUp(500);
        $(".close").css({
            display: "none",
        });
        $(".burger").css({
            display: "flex",
        });
    };
    const moveTo2Mob = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section2",
                offsetY: 65,
            },
            ease: Power4.easeInOut,
        });
        $(".header-mob").slideUp(500);
        $(".close").css({
            display: "none",
        });
        $(".burger").css({
            display: "flex",
        });
    };
    const moveTo3Mob = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section3",
                offsetY: 65,
            },
            ease: Power4.easeInOut,
        });
        $(".header-mob").slideUp(500);
        $(".close").css({
            display: "none",
        });
        $(".burger").css({
            display: "flex",
        });
    };
    const moveTo4Mob = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section4",
                offsetY: 65,
            },
            ease: Power4.easeInOut,
        });
        $(".header-mob").slideUp(500);
        $(".close").css({
            display: "none",
        });
        $(".burger").css({
            display: "flex",
        });
    };
    const moveTo5Mob = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section5",
                offsetY: 65,
            },
            ease: Power4.easeInOut,
        });
        $(".header-mob").slideUp(500);
        $(".close").css({
            display: "none",
        });
        $(".burger").css({
            display: "flex",
        });
    };
    /* Move To Top Button */
    const handleToTop = () => {
        gsap.to(window, {
            scrollTo: {
                y: "#section1",
                offsetY: 65,
            },
            ease: Power4.easeInOut,
        });
    };
    /* Mobile Header Visibility */
    const showMobHeader = () => {
        $(".header-mob").slideDown(500);
        $(".close").css({
            display: "flex",
        });
        $(".burger").css({
            display: "none",
        });
    };
    const hideMobHeader = () => {
        $(".header-mob").slideUp(500);
        $(".close").css({
            display: "none",
        });
        $(".burger").css({
            display: "flex",
        });
    };
    return (
        <div className="space">
            <img src={HeroBanner} alt="" className="space-bg"/>
            {/* MoveToTop Button */}
            <span className="moveToTop" onClick={handleToTop}>
        <MdArrowUpward/>
      </span>

            {/* Header */}
            <div className="header">
                <div className="box">
                    <div className="header-content">
                        <img onClick={moveTo1} src={Logo} alt=""/>
                        <div className="header-pc">
                            <a onClick={moveToHome} className="mt mtHome">
                                Home
                            </a>
                            <a onClick={moveTo1} className="mt mt2">
                                About
                            </a>
                            <a onClick={moveTo2} className="mt mt3">
                                Tokenomics
                            </a>
                            <a onClick={moveTo3} className="mt mt4">
                                Use Cases
                            </a>
                            <a onClick={moveTo4} className="mt mt5">
                                Metaverse
                            </a>
                            <a onClick={moveTo5} className="mt mt5">
                                How To Buy
                            </a>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Ecosystem
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        Exotic Swap Dex (coming soon)
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        Planet Exotic (coming soon)
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        Exotic Wallet (coming soon)
                                    </Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    NFT Market
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="https://cryptosnowmen.com/">
                                        CryptoSnowmen Relaunch (Coming Soon)
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        Exotic Cubs NFT (coming soon)
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                       Plethora Moons NFT (coming soon)
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        Early Access Pass (coming soon)
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        Robot Frens (coming soon)
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Whitepaper
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="https://exoticmetaverse.io/Exotic-Metaverse-Lite-Paper.pdf">
                                        Litepaper
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <MdMenu className="burger" onClick={showMobHeader}/>
                        <MdClose className="close" onClick={hideMobHeader}/>
                        <div className="header-mob">
                            <main>
                                <a onClick={moveToHome} className="mt mtHome">
                                    Home
                                </a>
                                <a onClick={moveTo1Mob} className="mt mt2">
                                    About
                                </a>
                                <a onClick={moveTo2Mob} className="mt mt3">
                                    Tokenomics
                                </a>
                                <a onClick={moveTo3Mob} className="mt mt4">
                                    Use Cases
                                </a>
                                <a onClick={moveTo4Mob} className="mt mt5">
                                    Roadmap
                                </a>
                                <a onClick={moveTo5Mob} className="mt mt5">
                                    Team
                                </a>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        Ecosystem
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            className={"mobiledropdownitems"}>
                                            Exotic Swap (coming soon)
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={"mobiledropdownitems"}>
                                            Planet Exotic (coming soon)
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={"mobiledropdownitems"}>
                                            Exotic Wallet (coming soon)
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        NFT Market
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="https://cryptosnowmen.com/"
                                                       className={"mobiledropdownitems"}>
                                            CryptoSnowmen (Relaunch Coming soon)
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={"mobiledropdownitems"}>
                                            ExoticCubs NFT (coming soon)
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={"mobiledropdownitems"}>
                                            Plethora Moons NFT (coming soon)
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={"mobiledropdownitems"}>
                                            Early Access Pass (coming soon)
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={"mobiledropdownitems"}>
                                            Robot Frens (coming soon)
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        Whitepaper
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            href="https://exoticmetaverse.io/Exotic-Metaverse-Lite-Paper.pdf"
                                            className={"mobiledropdownitems"}>
                                            Litepaper
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </main>
                        </div>
                    </div>
                </div>
            </div>

            {/* Hero Section */}
            {/*    <div className="hero-banner">*/}
            {/*        /!* <div className="hero-banner-content">*/}
            {/*  <div className="box">*/}
            {/*    <h1>*/}
            {/*      <span>EXOTIC</span> METAVERSE*/}
            {/*    </h1>*/}
            {/*    <h2>*/}
            {/*      Exotic Metaverse is a decentralized Gaming Metaverse Application*/}
            {/*      NFT Marketplace and also a Decentralized Exchange*/}
            {/*      The Metaverse will use Exotic Token*/}
            {/*      as in game virtual currency*/}
            {/*    </h2>*/}
            {/*  </div>*/}
            {/*</div> *!/*/}
            {/*    </div>*/}

            <div className="hero-banner">

            </div>

            <div className="hero-banner1">

            </div>

            {/* About Section */}
            <div className="about section " id="section1">
                <div className="box">
                    <div className="about-content">
                        <h2 className="mainHeading">What is Exotic Metaverse </h2>
                        <main>
                            <h6>
                                Exotic Metaverse is a decentralized Gaming Metaverse Application, NFT Marketplace, and
                                Dex. The Metaverse network will run on Ethereum Based networks. The Metaverse will use Exotic Token as in game virtual
                                currency. This will allow users to interact with the environment and purcahse in game items using the token. Players may 
                                own a piece of the Metaverse through vitrual land NFTs, as well as play as nft characters from multiple collections. Players will earn
                                in game rewards, through randomized loot drops, and meet other players around the world in a 3d play space. Players can own vehicles,
                                buildings, and build their own mini-verse inside their space. 
                            </h6>
                            <h6>
                                Official Contract: 0xe8ad7606297166288db08e2c8ed64faa0bdd446b
                                <br/>
                                <b><a style={{color: "#000000A6"}}
                                      href={"https://bscscan.com/token/0xe8ad7606297166288db08e2c8ed64faa0bdd446b"}>
                                    https://bscscan.com/token/0xe8ad7606297166288db08e2c8ed64faa0bdd446b</a></b>
                            </h6>
                            <h6>
                                Exotic is the utility token that will be used for accessing the Metaverse and interacting inside the Virtual Environment:
                                <br/>
                                <br/>
                                ➥ Fees in processing transactions in the Metaverse.
                                <br/>
                                <br/>
                                ➥ Exotic Metaverse Tokens will give players ability to purchase in Game items and NFTs in the NFT Marketplace
                                <br/>
                                <br/>
                                ➥ Tokens will be used to cast votes in the Governance of Exotic Metaverse Dao 
                                <br/>
                                <br/>
                                ➥ In-game Virtual Currency, for use in games integrated with Exotic Metaverse platform.
                                <br/>
                                <br/>
                                ➥ Decentralized Swap Dex, for exchanging Play to Earn Games, Holding Exotic Tokens will reduce fees
                                <br/>
                                <br/>
                                ➥ Decentralized Wallet App for holding NFTs and Exotic Tokens on Android and iOS
                                <br/>
                                <br/>
                                ➥ Exotic Metaverse Token is only 10M Supply and can never have more then 10M Tokens
                            </h6>
                        </main>
                    </div>
                </div>
            </div>

            {/* Tokenomics Section */}
            <div className="token section" id="section2">
                <div className="box">
                    <h2 className="mainHeading">Tokenomics</h2>
                    <div className="token-content">
                        <div className="token-grid">
                            <div className="token-grid-cell">
                <span>
                  <img src={TokenImg1} alt=""/>
                </span>
                                <main>
                                    <h6>2% Automatic-Liquidity </h6>
                                    <p>
                                    </p>
                                </main>
                            </div>
                            <div className="token-grid-cell">
                <span>
                  <img src={TokenImg1} alt=""/>
                </span>
                                <main>
                                    <h6>2% Back to Holders holding over 5000 tokens</h6>
                                    <p>
                                    </p>
                                </main>
                            </div>
                            <div className="token-grid-cell">
                <span>
                  <img src={TokenImg2} alt=""/>
                </span>
                                <main>
                                    <h6>6% Variable Marketing and Development</h6>
                                    <p>                                        
                                    </p>
                                </main>
                            </div>
                            <div className="token-grid-cell">
                <span>
                  <img src={TokenImg3} alt=""/>
                </span>
                                <main>
                                    <h6>Tokens Locked with Defi Unicrypt Smart Contract</h6>
                                    <p>                                        
                                    </p>
                                </main>
                            </div>
                            <div className="token-grid-cell">
                <span>
                  <img src={TokenImg4} alt=""/>
                </span>
                                <main>
                                    <h6>30% Liquidity added to Decentralized Exchanges</h6>
                                    <p>
                                    </p>
                                </main>
                            </div>
                        </div>
                        <div className="token-graph-grid">
                            <div className="token-graph-grid-cell">
                                <h6>
                                    <b> 7%</b> locked for Marketing
                                </h6>
                                <h6>
                                    <b>3%</b> locked for Airdrop/giveaways
                                </h6>
                                <h6>
                                    <b>20%</b> locked for adding liquidty to Exotic Swap Dex
                                    platform, development for Metaverse, cex listings
                                </h6>
                                <h6>
                                    <b>5%</b> locked for future allocation for dev team to keep 
                                    the metaverse updated with content, security updates
                                    bugs, DLC content and NFT integrations.
                                </h6>
                                <h6>
                                    <b>30%</b> liquidity pancakeswap and Uniswap
                                </h6>
                                <h6>
                                    <b>35%</b> to public for the metaverse protocol use
                                </h6>
                            </div>
                            <div className="token-graph-grid-cell">
                                <div className="token-graph-box">
                                    {/*<ResponsiveContainer width="100%" height="100%">
                                        <PieChart width="100%" height="100%">
                                            <Pie
                                                data={data}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                outerRadius="85%"
                                                fill="#8884d8"
                                                dataKey="value"
                                            >
                                                {data.map((entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={COLORS[index % COLORS.length]}
                                                    />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>*/}
                                    <img src={chart} style={{width: "100%"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Use Cases Section */}
            <div className="use section" id="section3">
                <div className="box">
                    <h2 className="mainHeading">Use Cases</h2>
                    <div className="use-content">
                        <div className="use-grid">
                            <div className="use-grid-cell">
                <span>
                  <img src={UCimg1} alt=""/>
                </span>
                                <h6>NFT Marketplace</h6>
                            </div>
                            <div className="use-grid-cell">
                <span>
                  <img src={UCimg2} alt=""/>
                </span>
                                <h6>Exotic Swap</h6>
                            </div>
                            <div className="use-grid-cell">
                <span>
                  <img src={UCimg3} alt=""/>
                </span>
                                <h6>Planet Exotic</h6>
                            </div>
                            <div className="use-grid-cell">
                <span>
                  <img src={UCimg4} alt=""/>
                </span>
                                <h6>Gaming</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
            {/* About Section */}
            <div className="about2 section">
                <div className="box">
                    <div className="about-content"></div>
                </div>
            </div>

            
            {/* Footer */}
            <div className="footer" style={{backgroundColor: "white", padding: "1.5rem"}}>
                <div className="box">
                    <div className="footer-content" style={{display: "flex"}}>
                        <div className="footer-left">
                            <img src={Logo} alt="img" className={"footer-logo"} style={{width: "7.5rem"}}/>
                        </div>
                        <div className={"footer-right"}
                             style={{display: "flex", alignItems: "center", marginLeft: "auto"}}>
                            <a href={"https://www.facebook.com/Exot1cMetaverse"} style={{margin: "0 10px"}}>
                                <BsFacebook style={{fontSize: 20, color: "#D80081"}}/>
                            </a>
                            <a href={"https://discord.com/invite/exoticmetaverse"} style={{margin: "0 10px"}}>
                                <FaDiscord style={{fontSize: 25, color: "#D80081"}}/>
                            </a>
                            <a href={"https://www.youtube.com/channel/UCEYs74zGrpaDKFN08LjLnXg"}
                               style={{margin: "0 10px"}}>
                                <AiFillYoutube style={{fontSize: 25, color: "#D80081"}}/>
                            </a>
                            <a href={"https://www.t.me/ExoticMetaverse"} style={{margin: "0 10px"}}>
                                <BsTelegram style={{fontSize: 22, color: "#D80081"}}/>
                            </a>
                            <a href={"https://exoticmetaverse.medium.com/subscribe"} style={{margin: "0 10px"}}>
                                <AiFillMediumSquare style={{fontSize: 25, color: "#D80081"}}/>
                            </a>
                            <a href={"https://twitter.com/exoticmetaverse/"} style={{margin: "0 10px"}}>
                                <AiFillTwitterCircle style={{fontSize: 25, color: "#D80081"}}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
